<template>
  <fragment>
    <div class="layer_body">
      <div class="tbl_comm tbl_view">
        <table>
          <colgroup>
            <col style="width: 192px" />
            <col style="width: 608px" />
          </colgroup>
          <tbody>
            <tr>
              <th>대결 방법 <sup>*</sup></th>
              <td>
                <div class="box_choice">
                  <div class="item_choice">
                    <!-- disabled 상태시 disabled 클래스 추가 -->
                    <input
                      id="rdoHow1"
                      v-model="delegate.delegateFlag"
                      type="radio"
                      class="inp_choice"
                      name="rdoHow"
                      value="2"
                    />
                    <label class="lab_choice" for="rdoHow1">
                      <span class="ico_account ico_rdo" />위임
                    </label>
                  </div>
                  <div class="item_choice">
                    <!-- disabled 상태시 disabled 클래스 추가 -->
                    <input
                      id="rdoHow2"
                      v-model="delegate.delegateFlag"
                      type="radio"
                      class="inp_choice"
                      name="rdoHow"
                      value="1"
                    />
                    <label class="lab_choice" for="rdoHow2">
                      <span class="ico_account ico_rdo" />보류
                    </label>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>기간 <sup>*</sup></th>
              <td>
                <div class="box_picker">
                  <input
                    v-if="fromDateBeforeYesterday"
                    v-model="delegate.fromDate"
                    type="text"
                    class="txt_time"
                    disabled
                    style="color: #888888"
                  />
                  <flat-pickr
                    v-if="!fromDateBeforeYesterday"
                    v-model="delegate.fromDate"
                    class="txt_time"
                    :config="flatpickrConfigDayFrom"
                    @on-change="listenToOnChangeDayFrom"
                  />
                  <span class="ico_account ico_calendar" />
                  <span class="txt_bar">~</span>
                  <flat-pickr
                    v-model="delegate.toDate"
                    class="txt_time"
                    :config="flatpickrConfigDayTo"
                  />
                  <span class="ico_account ico_calendar" />
                </div>
              </td>
            </tr>
            <tr v-if="delegate.delegateFlag == 2">
              <th>위임자 <sup>*</sup></th>
              <td>
                <div class="box_search">
                  <comm-inp-search
                    class="w326"
                    :url="'/aprvl/orgEmp'"
                    :inputRef="'empInfo'"
                    :initValue="''"
                    :isDisplayResult="true"
                    :displayData="displaySearchResult"
                    @select="selectEmp"
                  />
                  <div class="group_people type_side">
                    <!-- 인풋과 함께 우측에 정렬될 때 : type_side 클래스 추가 -->
                    <strong class="screen_out">선택된 담당자 목록</strong>
                    <comm-item-emp
                      v-if="selectedEmp.empName"
                      :key="selectedEmp.empNo"
                      :empName="selectedEmp.empName"
                      :accountId="selectedEmp.accountId"
                      @remove-employee="removeEmp"
                    />
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>{{ delegate.delegateFlag == 1 ? "보류 사유" : "위임 사유" }}<sup>*</sup></th>
              <td>
                <Textarea
                  placeholder="100자 이내로 작성해주세요."
                  :max="100"
                  :value.sync="delegate.reason"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="layer_foot">
      <div class="wrap_btn">
        <button type="button" class="btn_medium btn_fourthly" @click.prevent="closeLayer">
          취소
        </button>
        <button type="button" class="btn_medium btn_primary" @click.prevent="checkValidation">
          확인
        </button>
      </div>
      <!-- <slot></slot> X(창닫기) -->
    </div>
  </fragment>
</template>

<script>
import ApiService from "@/services/ApiService";
import CommInpSearch from "@/_approval/components/common/CommInpSearch";
import CommItemEmp from "@/_approval/components/common/CommItemEmp";
import Textarea from "@/_approval/components/common/textarea/Textarea";
import { default as CommLayerMixin } from "@/_approval/mixins/common/commLayer.js";
import { default as CommFlatpickrMixin } from "@/_approval/mixins/common/commFlatpickr.js";
import { default as CommMomentMixin } from "@/_approval/mixins/common/commMoment.js";
import { default as CommToastMixin } from "@/_approval/mixins/common/commToast.js";
// import axios from 'axios'

export default {
  name: "PopDelegate",
  components: {
    CommInpSearch,
    CommItemEmp,
    Textarea,
  },
  mixins: [CommLayerMixin, CommFlatpickrMixin, CommMomentMixin, CommToastMixin],
  props: {
    curDelegate: {
      delegateAccountId: String,
      delegateDeptName: String,
      delegateEmpName: String,
      delegateEmpNo: String,
      delegateFlag: String,
      delegateId: String,
      empNo: String,
      fromDate: String,
      reason: String,
      toDate: String,
    },
  },
  data() {
    return {
      selectedEmp: {},
      delegate: {
        delegateFlag: "2",
        reason: "",
        fromDate: this._getMoment("YYYY-MM-DD"),
        toDate: this._getMoment("YYYY-MM-DD"),
        delegateEmpNo: "",
        delegateEmpName: "",
        delegateAccountId: "",
      },
      fromDateBeforeYesterday: false,
      initDelegate: {
        delegateFlag: "2",
        reason: "",
        fromDate: this._getMoment("YYYY-MM-DD"),
        toDate: this._getMoment("YYYY-MM-DD"),
        delegateEmpNo: "",
        delegateEmpName: "",
        delegateAccountId: "",
      },
      flatpickrConfigDayFrom: {},
      flatpickrConfigDayTo: {},
    };
  },
  created() {
    let yesterday = this._getCalcDay(-1, "YYYY-MM-DD");
    // this.flatpickrConfigDay.disable = [{
    //   from: '0001-01-01',
    //   to: yesterday
    // }]
    this.flatpickrConfigDay.minDate = "today";
    this.flatpickrConfigDayFrom = { ...this.flatpickrConfigDay };
    this.flatpickrConfigDayTo = { ...this.flatpickrConfigDay };
    if (this.curDelegate.delegateId) {
      this.delegate = this.curDelegate;
      let fromDate = this.delegate.fromDate;
      if (fromDate <= yesterday.replace(/-/g, "")) {
        this.fromDateBeforeYesterday = true;
      }
      this.delegate.fromDate = this.$options.filters.date(fromDate);
      this.delegate.toDate = this.$options.filters.date(this.delegate.toDate);
      this.selectedEmp = {
        empNo: this.delegate.delegateEmpNo,
        empName: this.delegate.delegateEmpName,
        accountId: this.delegate.delegateAccountId,
      };
    }
  },
  mounted() {},
  beforeDestroy() {
    this.delegate = { ...this.initDelegate };
    this.removeEmp();
  },
  methods: {
    displaySearchResult(dataList) {
      dataList.data.map(function (value) {
        if (!value) return "";
        return (value.display = value.accountId + "(" + value.empName + ")");
      });
      // dataList.forEach((value) => {
      //   value.display = value.accountId + '(' + value.empName + ')'
      // })
    },
    selectEmp(emp) {
      this.selectedEmp = emp;
    },
    removeEmp() {
      this.selectedEmp = {};
    },
    checkValidation() {
      if (this.delegate.delegateFlag === "2" && !this.selectedEmp.empName) {
        const layer = {};
        layer.type = "alert";
        layer.order = 2;
        layer.content = "위임자를 선택해주세요";
        this._showLayer(layer);
        return false;
      }
      if (!this.delegate.reason) {
        let type;
        if (this.delegate.delegateFlag === "1") {
          type = "보류";
        } else {
          type = "위임";
        }
        const layer = {};
        layer.type = "alert";
        layer.order = 2;
        layer.content = type + " 사유를 입력해주세요.";
        this._showLayer(layer);
        return false;
      }
      if (!this.delegate.toDate) {
        const layer = {};
        layer.type = "alert";
        layer.order = 2;
        layer.content = "종료 일자를 입력해주세요.";
        this._showLayer(layer);
        return false;
      }

      this.insertDelegate();

      // if (this.curDelegate.delegateId) {
      //   axios.post('/api/deleteDelegate', {
      //     delegateId: this.curDelegate.delegateId
      //   })
      //     .then((res) => {
      //       this.insertDelegate()
      //     })
      //     .catch((error) => {
      //       console.log(error)
      //     })
      // } else {
      //   this.insertDelegate()
      // }
    },
    insertDelegate() {
      let data = { ...this.delegate };

      if (data.delegateFlag === "2") {
        data.delegateEmpNo = this.selectedEmp.empNo;
      } else {
        this.removeEmp();
        data.delegateEmpNo = "";
      }

      this.saveDelegate();
      // axios.post('/api/insertDelegate', data)
      //   .then((res) => {
      //     if (this.curDelegate.delegateId) {
      //       this.toast('대결 정보가 수정되었습니다.')
      //     } else {
      //       this.toast('대결 지정이 완료되었습니다.')
      //     }
      //     this._okPopup()
      //   })
      //   .catch((error) => {
      //     console.log(error)
      //   })
    },
    getObjToSend() {
      let type = this.delegate.delegateFlag === "1" ? "H" : "D";
      let data = { ...this.delegate };

      if (type === "D") {
        data.delegateEmpNo = this.selectedEmp.empNo;
      } else {
        data.delegateEmpNo = "";
      }

      data.fromDate = data.fromDate.replace(/-/g, "");
      data.toDate = data.toDate.replace(/-/g, "");

      let obj = {
        dlgtEmpNo: data.delegateEmpNo,
        dlgtType: type,
        reason: this.delegate.reason,
        strDate: data.fromDate,
        endDate: data.toDate,
      };

      return obj;
    },
    async saveDelegate() {
      const path = `${this.$apiPath.APRVL_DLGT}`;
      const obj = this.getObjToSend();
      const result = await ApiService.shared.postData(path, obj);

      if (result.code !== "200") {
        this._showLayer({ type: "alert", content: result.text });
        return;
      }

      this.closeLayer();
    },
    closeLayer() {
      this.$parent.$destroy();
      this._closeLayer();
    },
    toast(message) {
      let toast = {};
      toast.content = message;
      this._showToast(toast);
    },
    listenToOnChangeDayFrom(dObj, dStr, fp, dayElem) {
      // dObj는 range date의 시작일, 종료일이 배열로 저장되어 있음
      this.flatpickrConfigDayTo.minDate = dStr;
      this.flatpickrConfigDayTo = { ...this.flatpickrConfigDayTo };
    },
  },
};
</script>
